import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import SEO from "../components/SEO"
import { Link } from "gatsby";

const InlineLink = ({ children, ...rest }) => (
  <Link
    className="border-b-4 border-green-400 hover:bg-green-200"
    {...rest}
  >
    {children}
  </Link>
)

const IndexPage = () => {
  return (
    <main className="site">
      <SEO
        title="William Di Luigi - Software Engineer"
        description="I am a Software Engineer living in Zürich, and I love building things that are useful to people."
      />

      <Navbar />

      <div className="site-content slim">
        <div className="float-right w-1/3 ml-8">
          <StaticImage
            className="rounded-lg"
            src="../images/sicily.jpg"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={450}
            height={500}
          />
        </div>

        <div className="text-lg">
          <p className="text-2xl">
            Hi there, I'm William! <span role="img" aria-label="Hand Waving">👋</span>
          </p>

          <p className="mt-2">
            I am a Software Engineer living in Zürich, and I love building
            things that are useful to people. My main programming languages
            are: JavaScript, TypeScript, C++, Python, and lately I'm learning
            Go.
          </p>

          {/* <p className="mt-2">
            I'm active in the
            {' '}<span className="italic">Competitive Programming</span>{' '}
            scene: as a contestant, in 2011, I've won a
            {' '}<InlineLink target="_blank" to="https://stats.olinfo.it/contestant/8fcbe04a9edb183f72a42c4ddca5106e">gold medal</InlineLink>{' '}
            at Italy's national finals of the competition, and later
            as an organizer I contributed to the development of
            {' '}<InlineLink target="_blank" to="https://github.com/cms-dev/cms">the software</InlineLink>{' '}
            used to run the grading server, as well as authoring many of the
            problems that over the years were featured in the competition.
          </p> */}

          <p className="mt-2">
            In 2013, I helped create
            {' '}<InlineLink target="_blank" to="https://training.olinfo.it/">training.olinfo.it</InlineLink>{' '}
            — what was initially just a small community of competitive programming enthusiasts,
            quickly grew to become the official training platform for the
            {' '}<span className="italic">Italian Olympiads in Informatics</span>,
            and it's now actively used by thousands of high-school students from all over Italy.
            Over the course of almost a decade, this platform has helped
            to improve Italy's results when compared to other countries in
            the same competition.
          </p>

          <p className="mt-2">
            In 2016, I obtained my B.Sc. in Computer Science from
            {' '}<InlineLink target="_blank" to="https://www.unibo.it/en">University of Bologna</InlineLink>.
          </p>

          <p className="mt-2">
            In 2019, I obtained my M.Sc. in Computer Engineering from
            {' '}<InlineLink target="_blank" to="https://www.polimi.it/en">Polytechnic University of Milan</InlineLink>.
          </p>

          <p className="mt-2">
            In 2020, I joined
            {' '}<InlineLink target="_blank" to="https://smallpdf.com/">Smallpdf</InlineLink>{' '}
            as a Frontend Developer.
          </p>

          <p className="mt-2">
            Since 2022, I work for
            {' '}<InlineLink target="_blank" to="https://google.com/">Google</InlineLink>{' '}
            as a Software Engineer.
          </p>
        </div>
      </div>

      <Footer />
    </main>
  )
}

export default IndexPage
